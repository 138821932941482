// ==================================================
//  メインビジュアル
//   - スライダー
// ==================================================

var splideMainvisual = new Splide(".splide-js-mainvisual", {
  type: "fade",
  rewind: true,
  autoplay: "true",
  speed: 2400,
  interval: 4200,
  arrows: false,
  pagination: false,
});

splideMainvisual.mount();
